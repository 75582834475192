import React, { FC } from 'react';
import useCollapse from '@hooks/helpers/useCollapse';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import Input from '@components/common/input';
import Select from '@components/common/select';
import Textarea from '@components/common/textarea';
import styles from './styles.module.scss';
import FormField from '@components/form_field';
import { useFormContext } from 'react-hook-form';
import { EDdrStatus, TContact } from '@xeppt/xeppt-sdk/types';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useUserContext } from '@hooks/context/useUserContext';
import useModalContext from '@hooks/context/useModalContext';
import { modalIds } from '@const/modals';
import { apiAccountService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiQuery } from '@hooks/api/useApiQuery';
import Skeleton from '@components/common/skeleton';

interface IProps {
    onNextStep: () => void;
    contactList: TContact[];
}

const Info: FC<IProps> = ({ onNextStep, contactList }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'e_transfer.request_money.info'
    });
    const { labelLocale, submitLocale } = useLocales();
    const { isOpened, onToggle, contentStyles, contentRef } = useCollapse(true);
    const form = useFormContext();
    const { onClose, onOpen, handleChangeLoading } = useModalContext();
    const navigate = useNavigate();
    const { account, user, refetchAccount } = useUserContext();
    const { requestSuccessLocale, requestErrorLocale } = useLocales();

    const onChangeInterac = () => {
        onOpen({
            modalId: modalIds.UPDATE_INTERAC,
            onSubmit: ({ interacEmail }: { interacEmail: string }) => {
                handleChangeLoading(true);
                apiAccountService
                    .updateInteracEmail(interacEmail)
                    .then(() => {
                        requestSuccessLocale('update_interac');
                        refetchAccount();
                        onClose();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const { data: ddrStatus, isLoading: isDdrLoading } = useApiQuery({
        method: () => apiAccountService.checkETransferDdr()
    });

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.content}>
                <Typography variant="body3" className={styles.send_disclaimer}>
                    {t('description')}
                </Typography>
            </div>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Typography variant="h4">{t('from')}</Typography>
                    <div className={styles.actions}>
                        <Button
                            variant="outlined-dark"
                            size="small"
                            onClick={() => navigate(routes.e_transfer_contacts)}>
                            {submitLocale('add_new')}
                        </Button>
                    </div>
                </div>
                <FormField<string | number>
                    name="contact"
                    renderComponent={(props) => (
                        <Select
                            full
                            label={labelLocale('contact')}
                            items={
                                contactList?.map((item) => ({
                                    label: `${item.firstName} ${item.lastName} (${item.email || `+${item.phone}`})`,
                                    value: item.id
                                })) || []
                            }
                            {...props}
                        />
                    )}
                    rules={{ required: true }}
                />
                <FormField
                    name="amount"
                    renderComponent={(props) => (
                        <Input
                            full
                            label={labelLocale('amount')}
                            prefix="CAD"
                            rightIcon="canadian"
                            onlyNumbers
                            {...props}
                        />
                    )}
                    rules={{ required: true }}
                />
                <FormField
                    name="message"
                    renderComponent={(props) => (
                        <Textarea full label={labelLocale('message_optional')} {...props} />
                    )}
                />
            </div>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Typography variant="h4">{t('by')}</Typography>
                    <div className={styles.actions}>
                        <Typography variant="body3">
                            {user?.profile?.firstName} {user?.profile?.lastName} (
                            {account?.eTransferAccount?.interacEmail})
                        </Typography>
                        <Button leftIcon="edit" onClick={onChangeInterac} />
                    </div>
                </div>
                {isDdrLoading ? (
                    <Skeleton className={styles.skeleton} />
                ) : (
                    ddrStatus !== EDdrStatus.ENABLED && (
                        <Typography variant="body3" className={styles.balance}>
                            {t('autodeposit')}
                        </Typography>
                    )
                )}
            </div>
            {ddrStatus !== EDdrStatus.ENABLED ? (
                <Button
                    variant="primary"
                    size="normal"
                    onClick={() => navigate(routes.e_transfer_autodeposit)}>
                    {submitLocale('go_to_autodeposit')}
                </Button>
            ) : (
                <Button
                    variant="primary"
                    size="normal"
                    onClick={onNextStep}
                    disabled={!form.formState.isValid}>
                    {submitLocale('next')}
                </Button>
            )}
            <div className={styles.fees}>
                <Button onClick={onToggle}>
                    <div className={`${styles.icon} ${isOpened ? styles.isOpen : ''}`} />{' '}
                    <Typography variant="h5">{t('fees')}</Typography>
                </Button>
                <div
                    className={`${styles.fees_container} ${isOpened ? styles.isOpen : ''}`}
                    style={contentStyles}
                    ref={contentRef}>
                    <div className={styles.fees_wrapper}>
                        <Typography variant="h5">{t('fee_personal')}</Typography>
                        <div className={styles.row}>
                            <Typography>{t('fee_personal_1')}</Typography>
                            <Typography>{t('fee_personal_2')}</Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('fee_personal_3')}</Typography>
                            <Typography>$5 {t('fee_personal_4')}</Typography>
                        </div>
                    </div>
                    <div className={styles.fees_wrapper}>
                        <Typography variant="h5">{t('fee_business')}</Typography>
                        <div className={styles.row}>
                            <Typography>{t('fee_business_1')}</Typography>
                            <Typography>$1.50 {t('fee_business_2')}</Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('fee_business_3')}</Typography>
                            <Typography>$5 {t('fee_business_4')}</Typography>
                        </div>
                    </div>
                </div>
                <div className={styles.notes}>
                    {t('notes')}
                    <div className={styles.row}>
                        <span>1.</span>
                        <div>{t('notes_1')}</div>
                    </div>
                    <div className={styles.row}>
                        <span>2.</span>
                        <div>{t('notes_2')}</div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Info;
