import React, { FC, useEffect } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { IModalComponentProps } from '@context/modal_context';
import useModalContext from '@hooks/context/useModalContext';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider } from 'react-hook-form';
import FormField from '@components/form_field';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const ChangePasswordModal: FC<IModalComponentProps> = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'change_password'
    });
    const { labelLocale } = useLocales();
    const { changeModalData, handleChangeDisable } = useModalContext();
    const form = useForm({
        resolver: zodResolver(
            z
                .object({
                    oldPassword: z.string(),
                    newPassword: z.string(),
                    confirmNewPassword: z.string()
                })
                .refine((data) => data.newPassword === data.confirmNewPassword, {
                    message: 'Passwords mismatch',
                    path: ['confirmNewPassword']
                })
        )
    });

    useEffect(() => {
        handleChangeDisable(!form.formState.isValid);
        changeModalData(form.getValues());
    }, [!form.formState.isValid]);

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <div className={styles.content}>
                <FormProvider {...form}>
                    <div className={styles.row}>
                        <FormField
                            name="oldPassword"
                            renderComponent={(props) => (
                                <Input
                                    label={labelLocale('current_password')}
                                    type="password"
                                    full
                                    placeholder="••••••••"
                                    {...props}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <FormField
                            name="newPassword"
                            renderComponent={(props) => (
                                <Input
                                    label={labelLocale('new_password')}
                                    type="password"
                                    full
                                    placeholder="••••••••"
                                    {...props}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <FormField
                            name="confirmNewPassword"
                            renderComponent={(props) => (
                                <Input
                                    label={labelLocale('confirm_new_password')}
                                    type="password"
                                    full
                                    placeholder="••••••••"
                                    {...props}
                                />
                            )}
                        />
                    </div>
                </FormProvider>
            </div>
        </div>
    );
};

export default ChangePasswordModal;
