import React, { FC } from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import FormField from '@components/form_field';
import { useFormContext, useWatch } from 'react-hook-form';

interface IProps {
    handleNext: () => void;
}

const AmountStep: FC<IProps> = ({ handleNext }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'add_money.amount'
    });
    const { labelLocale, submitLocale } = useLocales();
    const { control } = useFormContext();
    const amount = useWatch({ name: 'amount', control });
    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <div className={styles.input_wrapper}>
                <FormField
                    name="amount"
                    renderComponent={(props) => {
                        return (
                            <Input
                                {...props}
                                prefix="CAD"
                                onlyNumbers
                                label={labelLocale('amount')}
                                full
                                rightIcon="canadian"
                            />
                        );
                    }}
                />
            </div>
            <Button disabled={amount <= 0} variant="primary" size="normal" onClick={handleNext}>
                {submitLocale('next')}
            </Button>
        </div>
    );
};

export default AmountStep;
