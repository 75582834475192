import React, { Dispatch, FC, SetStateAction } from 'react';
import styles from './styles.module.scss';
import Input from '@components/common/input';
import Button from '@components/common/button';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { TCreatePayee, TSearchedPayees } from '@xeppt/xeppt-sdk/types/billing';
import { apiBillingService } from '@api';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';

interface IProps {
    handleBack: () => void;
    chosenPayee?: string;
    payeesList: TSearchedPayees[];
    form: { accountNumber: string; alias?: string; id?: string; name?: string; code?: string };
    setForm: Dispatch<
        SetStateAction<{
            accountNumber: string;
            alias?: string;
            id?: string;
            name?: string;
            code?: string;
        }>
    >;
    isUpdate: boolean;
}

const Payee: FC<IProps> = ({ handleBack, chosenPayee, payeesList, isUpdate, setForm, form }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'pay_bills.manage_payees'
    });
    const { labelLocale, submitLocale, requestErrorLocale, requestSuccessLocale } = useLocales();

    const { handleRequest: handleUpdatePayee, isLoading: isUpdatePayeeLoading } = useApiMutation({
        method: () => {
            return apiBillingService
                .updatePayee(form.id as string, form as TCreatePayee)
                .then()
                .catch();
        },
        onSuccess: () => {
            handleBack();
            requestSuccessLocale('update_payee');
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleCreatePayee, isLoading: isCreatePayeeLoading } = useApiMutation({
        method: () => {
            return apiBillingService.createPayee({
                ...form,
                ...(payeesList.find((item) => item.code === chosenPayee) as TSearchedPayees)
            });
        },
        onSuccess: () => {
            setForm({
                alias: '',
                name: '',
                accountNumber: ''
            });
            handleBack();
            requestSuccessLocale('create_payee');
        },
        onError: requestErrorLocale
    });

    const handleAddPayee = () => {
        if (isUpdate && form.id && form.name && form.code) {
            handleUpdatePayee(undefined);
        }
        if (!isUpdate) {
            handleCreatePayee(undefined);
        }
    };

    return (
        <>
            {chosenPayee && (
                <motion.div {...pageAnimation} className={styles.content}>
                    <div
                        className={styles.form_row}
                        style={{ alignItems: 'flex-start', marginBottom: 24 }}>
                        <Input
                            full
                            label={labelLocale('account_number')}
                            helperText={t('help_account')}
                            value={form.accountNumber}
                            onChange={(val) =>
                                setForm((state) => ({ ...state, accountNumber: val }))
                            }
                        />
                        <Input
                            full
                            label={t('name_label')}
                            helperText={t('helper_name')}
                            value={form.alias}
                            onChange={(val) => setForm((state) => ({ ...state, alias: val }))}
                        />
                    </div>
                </motion.div>
            )}
            <Button
                variant="primary"
                size="normal"
                onClick={handleAddPayee}
                isLoading={isCreatePayeeLoading || isUpdatePayeeLoading}
                disabled={!chosenPayee || !form.accountNumber}>
                {submitLocale(isUpdate ? 'update_payee' : 'add_payee')}
            </Button>
            <Button
                leftIcon="arrow_left"
                onClick={() => {
                    handleBack();
                    setForm({ accountNumber: '', alias: '' });
                }}>
                {submitLocale('back')}
            </Button>
        </>
    );
};

export default Payee;
