import React, { FC, useMemo } from 'react';
import Typography from '@components/common/typography';
import moment from 'moment/moment';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { currencies } from '@const/currency';
import { enumTranslate } from '@locales/index';
import { useTranslation } from 'react-i18next';
import { Icon } from '@components/icons';
import {
    ETransactionAction,
    ETransactionMethod,
    ETransactionStatus,
    TTransactionLite
} from '@xeppt/xeppt-sdk/types';
import { getTransactionAction, prettifyAmount } from '@utils/index';

interface IProps {
    transaction: TTransactionLite;
    onClick?: (id: string) => void;
}

const moneyOutActions = [
    ETransactionAction.TRANSACTION_FEE,
    ETransactionAction.SEND_MONEY,
    ETransactionAction.REQUEST_CARD,
    ETransactionAction.BILL_PAYMENT
];

const cx = classNames.bind(styles);
const TransactionItem: FC<IProps> = ({ transaction, onClick }) => {
    const { t, i18n } = useTranslation('components', {
        keyPrefix: 'transaction_tables.transaction_item'
    });

    const isPay = useMemo(() => {
        const action = transaction.action;
        return moneyOutActions.includes(action);
    }, [transaction]);

    const amountStyles = useMemo(
        () =>
            cx([
                styles.amount,
                {
                    plus: !moneyOutActions.includes(transaction.action),
                    isCancel: transaction.status === ETransactionStatus.CANCELED
                }
            ]),
        [transaction]
    );

    const iconStyles = useMemo(
        () =>
            cx([
                styles.icon,
                {
                    isWallet: !isPay
                }
            ]),
        [transaction]
    );

    return (
        <button
            className={styles.table_item}
            onClick={() => {
                if (onClick) onClick(transaction.id);
            }}>
            <div className={styles.left}>
                <div className={iconStyles}>
                    {transaction.automatic && (
                        <Icon
                            className={styles.recurring_icon}
                            name="sync"
                            width={40}
                            height={40}
                        />
                    )}
                    {isPay ? (
                        <Icon name="arrow_up" width={24} height={24} />
                    ) : (
                        <Icon name="arrow_down" width={24} height={24} />
                    )}
                </div>
                <div className={styles.text_wrapper}>
                    <Typography variant="body1" weight="semibold">
                        {transaction.action === ETransactionAction.LOAD_BALANCE &&
                        transaction.method === ETransactionMethod.WALLET
                            ? t('load_card')
                            : getTransactionAction(transaction.action, t)}
                    </Typography>
                    <Typography variant="body3">
                        {moment(transaction.createdAt).format('MMM DD')},{' '}
                        {enumTranslate[transaction.method][i18n.language as 'en' | 'fr']}
                    </Typography>
                </div>
            </div>
            <Typography variant="body1" weight="bold" className={amountStyles}>
                {moneyOutActions.includes(transaction.action)
                    ? `-${currencies[transaction.currency as 'CAD'].symbol}${prettifyAmount(transaction.amount.toFixed(2))}`
                    : `${currencies[transaction.currency as 'CAD'].symbol}${prettifyAmount(transaction.amount.toFixed(2))}`}
            </Typography>
        </button>
    );
};

export default TransactionItem;
