import React, { FC, useMemo } from 'react';
import {
    capitalizeFirstLetter,
    getTagVariant,
    prettifyAmount,
    prettifyCardStatus
} from '@utils/index';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Status from '../../common/status';
import Typography from '../../common/typography';
import { ECardType, TCardLite } from '@xeppt/xeppt-sdk/types/card';
import xepptCard from '@svg/xeppt_card.svg';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';

interface IProps {
    card: TCardLite;
    className?: string;
    isBalance?: boolean;
    showStatus?: boolean;
}

const cx = classNames.bind(styles);
const Card: FC<IProps> = ({ card, className, isBalance, showStatus = true }) => {
    const { t } = useTranslation('sections', { keyPrefix: 'dashboard.cards' });
    const navigate = useNavigate();
    const rootStyles = useMemo(() => cx([styles.wrapper, className]), [className]);

    return (
        <div
            className={rootStyles}
            style={{ backgroundImage: `url(${xepptCard})` }}
            onClick={() => navigate(`${routes.xeppt_cards}/${card.id}`)}>
            {showStatus ? (
                <Status variant={getTagVariant(card.status)}>
                    {capitalizeFirstLetter(prettifyCardStatus(card.status))}
                </Status>
            ) : (
                <div />
            )}
            <div className={styles.content_wrapper}>
                <div className={styles.row}>
                    <div>
                        {isBalance && (
                            <div className={styles.amount}>
                                ${prettifyAmount(card.balance.toFixed(2))}
                            </div>
                        )}
                        <Typography className={styles.description}>{card.cardHolder}</Typography>
                    </div>
                    {(card.type === ECardType.PHYSICAL_SUPPLEMENTARY ||
                        card.type === ECardType.VIRTUAL_SUPPLEMENTARY) && (
                        <Typography className={styles.badge}>Supplementary</Typography>
                    )}
                </div>
                <Typography className={styles.number}>{card.cardNumber}</Typography>
            </div>
        </div>
    );
};

export default Card;
