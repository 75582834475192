export const routes = {
    dashboard: '/',
    xeppt_cards: '/xeppt-cards',
    notifications: '/notifications',
    xeppt_card: '/xeppt-cards/:id',
    order_xeppt_card: '/xeppt-cards/order-card',
    add_money_balance: '/xeppt-cards/add-money',
    transactions: '/transactions',
    transaction_details: '/transactions/:id',
    signin: '/sign-in',
    signup: '/sign-up',
    forgot_password: '/forgot-password',
    settings_profile: '/settings/profile',
    settings_business: '/settings/business',
    settings: '/settings',
    link_card_bank: '/link-card-or-bank',
    help_center: '/help-center',
    privacy_policy: '/privacy-policy',
    terms_of_service: '/terms-of-service',
    statements: '/statements',
    e_transfer: '/e-transfer',
    e_transfer_send: '/e-transfer/send',
    e_transfer_request: '/e-transfer/request',
    e_transfer_contacts: '/e-transfer/contacts',
    e_transfer_autodeposit: '/e-transfer/autodeposit',
    pay_bills: '/pay-bills',
    pay_bills_pay: '/pay-bills/pay-bills',
    pay_bills_payees: '/pay-bills/manage-payees',
    pay_bills_schedule: '/pay-bills/schedule-payments',
    manage_accounts: '/manage_accounts'
};
