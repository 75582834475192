import styles from './styles.module.scss';
import React, { useState } from 'react';
import Contact from '@sections/e_transfer/manage_contacts/contact';
import ContactsTable from '@sections/e_transfer/manage_contacts/contacts_table';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiWalletService } from '@api';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const ManageContactsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'e_transfer.manage_contacts'
    });
    const [isEdit, setIsEdit] = useState(false);

    const { data: contactList, handleRequest: refetchContacts } = useApiQuery({
        method: () => apiWalletService.getContactList()
    });

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Typography variant="h4">{t('title')}</Typography>
                    </div>
                </div>
                {isEdit ? (
                    <Contact
                        handleBack={() => {
                            setIsEdit(false);
                            refetchContacts();
                        }}
                    />
                ) : (
                    <ContactsTable
                        refetchContacts={refetchContacts}
                        contactList={contactList || []}
                        onAdd={() => setIsEdit(true)}
                    />
                )}
            </div>
        </motion.div>
    );
};

export default ManageContactsSection;
