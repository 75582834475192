import React, { FC, useEffect, useState } from 'react';
import FormField from '@components/form_field';
import { TAddress } from '@types';
import AddressAutocomplete from '@components/common/address';
import styles from './styles.module.scss';
import Input from '@components/common/input';
import { useFormContext, useWatch } from 'react-hook-form';
import { useLocales } from '@hooks/helpers/useLocales';
import { Country } from 'country-state-city';

interface IProps {
    name: string;
    disabled?: boolean;
    isFull?: boolean;
}

const FullAddress: FC<IProps> = ({ name, disabled, isFull = true }) => {
    const { labelLocale } = useLocales();
    const [isAddressFiled, setIsAddressField] = useState(false);
    const { control } = useFormContext();
    const [isRerender, setIsRerender] = useState(true);
    const { setValue } = useFormContext();

    const value = useWatch({ name, control });

    useEffect(() => {
        if (value?.address1) {
            setIsAddressField(true);
        }
    }, [value]);

    return (
        <div className={styles.wrapper}>
            <FormField<TAddress>
                name={name}
                renderComponent={({ onChange, ...props }) => (
                    <AddressAutocomplete
                        {...props}
                        onChange={(val) => {
                            onChange(val);
                            setValue(
                                `${name}.country`,
                                Country.getAllCountries().find(
                                    (item) => item.isoCode === val.country
                                )?.name || val.country
                            );
                            setIsRerender(false);
                            setTimeout(() => {
                                setIsRerender(true);
                            }, 0);
                        }}
                        helperText={isFull ? undefined : ''}
                        label={isFull ? undefined : ''}
                        full
                        disabled={disabled}
                    />
                )}
            />
            {isFull && isAddressFiled && isRerender && (
                <div className={styles.address_wrapper}>
                    <FormField
                        name={`${name}.address1`}
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                full
                                label={labelLocale('address_1')}
                                readOnly={disabled}
                            />
                        )}
                    />
                    <FormField
                        name={`${name}.address2`}
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                full
                                label={labelLocale('address_2')}
                                readOnly={disabled}
                            />
                        )}
                    />
                    <div className={styles.row}>
                        <FormField
                            name={`${name}.country`}
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label={labelLocale('country')}
                                    readOnly={disabled}
                                />
                            )}
                        />
                        <FormField
                            name={`${name}.city`}
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label={labelLocale('city')}
                                    readOnly={disabled}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <FormField
                            name={`${name}.region`}
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label={labelLocale('region')}
                                    readOnly={disabled}
                                />
                            )}
                        />
                        <FormField
                            name={`${name}.zipCode`}
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label={labelLocale('zipCode')}
                                    readOnly={disabled}
                                />
                            )}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default FullAddress;
