import React from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useLocales } from '@hooks/helpers/useLocales';
import Address from '@components/address';
import Input from '@components/common/input';
import DatePicker from '@components/date_picker';
import { useUserContext } from '@hooks/context/useUserContext';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiWalletService } from '@api';
import Select from '@components/common/select';
import FormField from '@components/form_field';
import { useFormContext, useWatch } from 'react-hook-form';
import { ECardType } from '@xeppt/xeppt-sdk/types/card';
import { ECardStatus, EUserStatus } from '@xeppt/xeppt-sdk/types';

const ThirdStep = () => {
    const { labelLocale } = useLocales();
    const { user } = useUserContext();
    const form = useFormContext();
    const watchedCardType = useWatch({ name: 'type', control: form.control });

    const { data: cards } = useApiQuery({
        method: () => apiWalletService.getCards()
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.column}>
                {watchedCardType === ECardType.PHYSICAL_SUPPLEMENTARY && (
                    <FormField<string | number>
                        name="cardId"
                        renderComponent={(props) => (
                            <Select
                                {...props}
                                full
                                label={labelLocale('primary_card')}
                                items={
                                    cards
                                        ?.filter(
                                            (item) =>
                                                item.type === ECardType.PHYSICAL &&
                                                item.status === ECardStatus.ACTIVE
                                        )
                                        .map((item) => ({
                                            value: item.id,
                                            label: `${item.cardHolder} ${item.cardNumber} $${item.balance}CAD`
                                        })) || []
                                }
                            />
                        )}
                    />
                )}
                <div className={styles.row}>
                    <Input
                        readOnly
                        value={user?.profile.firstName}
                        full
                        label={labelLocale('first_name')}
                    />
                    <Input
                        readOnly
                        value={user?.profile.lastName}
                        full
                        label={labelLocale('last_name')}
                    />
                </div>
                <DatePicker
                    readOnly
                    value={user?.profile.birthDate as string | undefined}
                    full
                    label={labelLocale('birth_date')}
                />
                {/*<Input value={user?.profile} full label={labelLocale('occupation')} />*/}
            </div>
            <Typography variant="body1">{labelLocale('shipping_address')}</Typography>
            <Address name="shippingAddress" />
        </div>
    );
};

export default ThirdStep;
