import React, { FC, useState } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider } from 'react-hook-form';
import FormField from '@components/form_field';
import { apiWalletService } from '@api';
import { TCreateContact } from '@xeppt/xeppt-sdk/types';
import { useLocales } from '@hooks/helpers/useLocales';
import Radio from '@components/common/radio';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';

interface IProps {
    handleBack: () => void;
}

const Contact: FC<IProps> = ({ handleBack }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'e_transfer.manage_contacts'
    });
    const form = useForm({});
    const {
        requestSuccessLocale,
        requestErrorLocale,
        labelLocale,
        validationLocale,
        submitLocale
    } = useLocales();
    const [isEmail, setIsEmail] = useState(true);

    const { handleRequest: onSubmit, isLoading } = useApiMutation({
        method: () => {
            const values = form.getValues();
            const data: TCreateContact = {
                firstName: values?.firstName,
                lastName: values?.lastName,
                phone: undefined,
                email: undefined
            };
            if (isEmail) {
                data.email = values?.email;
            } else {
                data.phone = values?.phone;
            }
            return apiWalletService.createContact(data);
        },
        onSuccess: () => {
            requestSuccessLocale('create_contact');
            handleBack();
        },
        onError: requestErrorLocale
    });

    return (
        <FormProvider {...form}>
            <motion.div {...pageAnimation} className={styles.content}>
                <Typography variant="body3" className={styles.send_disclaimer}>
                    {t('add_contact_description')}
                </Typography>
                <div className={styles.form_row}>
                    <FormField
                        name="firstName"
                        renderComponent={(props) => (
                            <Input label={labelLocale('first_name')} full {...props} />
                        )}
                        rules={{ required: true }}
                    />
                    <FormField
                        name="lastName"
                        renderComponent={(props) => (
                            <Input label={labelLocale('last_name')} full {...props} />
                        )}
                        rules={{ required: true }}
                    />
                </div>
            </motion.div>
            <div className={styles.content}>
                <Typography variant="body3" className={styles.ddr_info}>
                    {t('notify_1')} <span>{t('notify_2')}</span> {t('notify_3')}
                </Typography>
                <div className={styles.form_row}>
                    <Radio
                        className={styles.radio}
                        small
                        checked={isEmail}
                        onClick={() => setIsEmail(true)}
                    />
                    <FormField
                        name="email"
                        renderComponent={(props) => (
                            <Input
                                disabled={!isEmail}
                                label={labelLocale('email')}
                                full
                                {...props}
                            />
                        )}
                        rules={{
                            required: isEmail
                        }}
                    />
                    <FormField
                        name="confirmEmail"
                        renderComponent={(props) => (
                            <Input
                                disabled={!isEmail}
                                label={labelLocale('confirm_email')}
                                full
                                {...props}
                            />
                        )}
                        rules={{
                            required: isEmail,
                            validate: (val: string) => {
                                return (
                                    val === form.getValues('email') ||
                                    validationLocale('emailConfirm')
                                );
                            }
                        }}
                    />
                </div>
                <div className={styles.form_row}>
                    <Radio
                        className={styles.radio}
                        small
                        checked={!isEmail}
                        onClick={() => setIsEmail(false)}
                    />
                    <FormField
                        name="phone"
                        renderComponent={(props) => (
                            <Input
                                disabled={isEmail}
                                label={labelLocale('enter_contact_phone')}
                                full
                                {...props}
                                mask="(999) 999 99 99"
                            />
                        )}
                        rules={{ required: !isEmail }}
                    />
                    <FormField
                        name="confirmPhone"
                        renderComponent={(props) => (
                            <Input
                                disabled={isEmail}
                                label={labelLocale('enter_contact_confirm_phone')}
                                full
                                {...props}
                                mask="(999) 999 99 99"
                            />
                        )}
                        rules={{
                            required: !isEmail,
                            validate: (val: string) => {
                                return (
                                    val === form.getValues('phone') ||
                                    validationLocale('verify_contact_form')
                                );
                            }
                        }}
                    />
                </div>
            </div>
            <Button
                variant="primary"
                size="normal"
                onClick={onSubmit}
                isLoading={isLoading}
                disabled={!form.formState.isValid}>
                {t('add_contact_submit')}
            </Button>
            <Button leftIcon="arrow_left" onClick={handleBack}>
                {submitLocale('back')}
            </Button>
        </FormProvider>
    );
};

export default Contact;
