import React from 'react';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import Typography from '@components/common/typography';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const NotificationsLayout = () => {
    const { t } = useTranslation('notifications');
    const navigate = useNavigate();

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.head}>
                <Button onClick={() => navigate(routes.dashboard)} leftIcon="arrow_left">
                    {t('title')}
                </Button>
            </div>
            <div className={styles.content}>
                <div className={styles.item}>
                    <Typography className={styles.time} variant="body3">
                        2hr ago
                    </Typography>
                    <Typography className={styles.title} fw={400} variant="h5">
                        This is an unread message.asdf adsf asdf asdf
                    </Typography>
                    <span className={`${styles.status} ${styles.red}`} />
                </div>
                <div className={styles.item}>
                    <Typography className={styles.time} variant="body3">
                        2hr ago
                    </Typography>
                    <Typography className={styles.title} fw={400} variant="h5">
                        This is an unread message.asdf adsf asdf asdfThis is an unread message.asdf
                        adsf asdf asdfThis is an unread message.asdf adsf asdf asdfThis is an unread
                        message.asdf adsf asdf asdfThis is an unread message.asdf adsf asdf asdfThis
                        is an unread message.asdf adsf asdf asdfThis is an unread message.asdf adsf
                        asdf asdfThis is an unread message.asdf adsf asdf asdf
                    </Typography>
                    <span className={`${styles.status} ${styles.red}`} />
                </div>
                <div className={styles.item}>
                    <Typography className={styles.time} variant="body3">
                        2hr ago
                    </Typography>
                    <Typography className={styles.title} fw={400} variant="h5">
                        This is an unread message.asdf adsf asdf asdf
                    </Typography>
                    <span className={`${styles.status} ${styles.red}`} />
                </div>
            </div>
        </motion.div>
    );
};

export default NotificationsLayout;
