import { TAddress } from '@types';
import { z } from 'zod';
import { validator } from '@xeppt/xeppt-sdk';
import { ECardType } from '@xeppt/xeppt-sdk/types/card';

export interface IProps {
    currentStep: number;
    cardType?: ECardType;
    messages: {
        shippingAddress: TAddress;
    };
}

export const requestCardValidation = ({ currentStep, messages, cardType }: IProps) => {
    const steps = [
        z.object({}),
        z.object({
            ...(cardType === ECardType.PHYSICAL_SUPPLEMENTARY && { cardId: z.string() }),
            shippingAddress: validator.address(messages.shippingAddress)
        }),
        z.object({})
    ];
    return steps[currentStep];
};
