import React from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import NewCardRow from '@components/cards/new_card_row';
import CardRow from '@components/cards/card_row';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '@hooks/context/useUserContext';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';
import Skeleton from '@components/common/skeleton';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiWalletService } from '@api';
import { EUserStatus } from '@xeppt/xeppt-sdk/types';

const CardsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'xeppt_cards.cards'
    });
    const navigate = useNavigate();
    const { isDataLoading, user } = useUserContext();
    const { verifiedAction } = useVerificationGuard();

    const { data: cards, isLoading: isCardsLoading } = useApiQuery({
        method: () => apiWalletService.getCards()
    });

    return (
        <div className={styles.wrapper}>
            <Typography variant="body1" lh="120%" weight="semibold">
                {t('title')}
            </Typography>
            <div className={styles.content}>
                <NewCardRow
                    onClick={() => verifiedAction(() => navigate(routes.order_xeppt_card))}
                />
                {isDataLoading || isCardsLoading ? (
                    <Skeleton className={styles.skeleton} />
                ) : (
                    cards?.map((item) => (
                        <CardRow
                            onClick={() => navigate(`${routes.xeppt_cards}/${item.id}`)}
                            key={item.id}
                            card={item}
                        />
                    ))
                )}
            </div>
        </div>
    );
};

export default CardsSection;
