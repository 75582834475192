import React, { createContext, useEffect, useMemo, useState } from 'react';
import { TBusinessProfile, TIndividualProfile } from '@xeppt/xeppt-sdk/types/user';
import { apiAccountService, apiAuthService, apiUserService } from '@api';
import { routes } from '@const/routes';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { isBusinessUserTypeGuard, TBusinessDetails } from '@types';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { TAccount } from '@xeppt/xeppt-sdk/types';
import { useLocales } from '@hooks/helpers/useLocales';
import { AnimatePresence } from 'framer-motion';

type TUserContext = {
    user?: TIndividualProfile | TBusinessProfile;
    account?: TAccount;
    businessData?: TBusinessDetails;
    handleLogout: () => void;
    refetchAccount: () => void;
    refetchUser: () => void;
    isDataLoading: boolean;
};

const initialState: TUserContext = {
    user: undefined,
    account: undefined,
    businessData: undefined,
    handleLogout: () => Promise.resolve(),
    refetchAccount: () => Promise.resolve(),
    refetchUser: () => Promise.resolve(),
    isDataLoading: true
};

const authRoutes = [
    routes.signin,
    routes.forgot_password,
    routes.signup,
    routes.terms_of_service,
    routes.privacy_policy
];

export const UserContext = createContext<TUserContext>(initialState);

export const UserContextProvider = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [businessData, setBusinessData] = useState<TBusinessDetails>();
    const [user, setUser] = useState<TBusinessProfile | TIndividualProfile>();
    const [account, setAccount] = useState<TAccount>();
    const [isAuth, setIsAuth] = useState(true);
    const { requestErrorLocale } = useLocales();

    const { isLoading: isUserLoading, handleRequest: refetchUser } = useApiQuery({
        method: () => apiUserService.profile(),
        condition: !isAuth,
        deps: [isAuth],
        onSuccess: (data) => {
            setUser(data);
            if (isBusinessUserTypeGuard(data)) {
                if (data.business) {
                    const { business } = data;
                    setBusinessData(business);
                }
            }
        },
        onError: () => {
            navigate(routes.signin);
        }
    });

    const { handleRequest: refetchAccount, isLoading: isAccountLoading } = useApiQuery({
        method: () => apiAccountService.getAccount(),
        onSuccess: (data) => {
            setAccount(data);
        },
        condition: !isAuth,
        deps: [isAuth]
    });

    useEffect(() => {
        if (authRoutes.includes(location.pathname)) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    }, [location]);

    const handleLogout = () => {
        apiAuthService
            .logout()
            .then(() => {
                navigate(routes.signin);
                setUser(undefined);
                setBusinessData(undefined);
                setAccount(undefined);
                localStorage.removeItem('client-metadata');
            })
            .catch(requestErrorLocale);
    };

    const isDataLoading = useMemo(() => {
        return isUserLoading || isAccountLoading;
    }, [isUserLoading, isAccountLoading]);

    return (
        <UserContext.Provider
            value={{
                user,
                account,
                businessData,
                handleLogout,
                refetchAccount,
                refetchUser,
                isDataLoading
            }}>
            <AnimatePresence>
                <Outlet />
            </AnimatePresence>
        </UserContext.Provider>
    );
};
